import { createSlice } from "@reduxjs/toolkit"


  const initialState = {
    jobId: typeof window !== 'undefined' &&
           JSON.parse(localStorage.getItem('windId')),
  };
  // ... rest of your code


export const windloadSlice = createSlice({
  name: "windloadSlice",
  initialState,
  reducers: {
    getJobId: (state, action) => {
      localStorage.setItem('windId',action.payload)
      return {
        ...state,
        jobId: action.payload,
      }
    },
  },
  extraReducers: {},
})

// Action creators are generated for each case reducer function
export const { getJobId } = windloadSlice.actions
export default windloadSlice.reducer
