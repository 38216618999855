import React, { useState, useEffect } from "react"

const GoTop = () => {
  const [showScroll, setShowScroll] = useState(false)

  const isBrowser = typeof window !== "undefined"

  useEffect(() => {
    if (isBrowser) {
      window.addEventListener("scroll", checkScrollTop)
      return function cleanup() {
        isBrowser && window.removeEventListener("scroll", checkScrollTop)
      }
    }
  })

  const checkScrollTop = () => {
    if (isBrowser) {
      if (!showScroll && (isBrowser && window.pageYOffset) > 100) {
        setShowScroll(true)
      } else if (showScroll && (isBrowser && window.pageYOffset) <= 100) {
        setShowScroll(false)
      }
    }
  }

  const scrollTop = () => {
    if (isBrowser) isBrowser && window.scrollTo({ top: 0, behavior: "smooth" })
  }

  return (
    <>
      <div
        className="go-top"
        onClick={scrollTop}
        style={{
          display: showScroll ? "block" : "none",
        }}
        aria-hidden="true"
      >
        <i className="bx bx-up-arrow-alt"></i>
      </div>
    </>
  )
}

export default GoTop
