exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cladcut-js": () => import("./../../../src/pages/cladcut.js" /* webpackChunkName: "component---src-pages-cladcut-js" */),
  "component---src-pages-cladcuthome-js": () => import("./../../../src/pages/cladcuthome.js" /* webpackChunkName: "component---src-pages-cladcuthome-js" */),
  "component---src-pages-cladcutjobs-js": () => import("./../../../src/pages/cladcutjobs.js" /* webpackChunkName: "component---src-pages-cladcutjobs-js" */),
  "component---src-pages-dashboard-js": () => import("./../../../src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-dxf-js": () => import("./../../../src/pages/dxf.js" /* webpackChunkName: "component---src-pages-dxf-js" */),
  "component---src-pages-facade-js": () => import("./../../../src/pages/facade.js" /* webpackChunkName: "component---src-pages-facade-js" */),
  "component---src-pages-facade-static-js": () => import("./../../../src/pages/facade-static.js" /* webpackChunkName: "component---src-pages-facade-static-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-profile-details-js": () => import("./../../../src/pages/profile-details.js" /* webpackChunkName: "component---src-pages-profile-details-js" */),
  "component---src-pages-project-landing-js": () => import("./../../../src/pages/project-landing.js" /* webpackChunkName: "component---src-pages-project-landing-js" */),
  "component---src-pages-sign-up-js": () => import("./../../../src/pages/sign-up.js" /* webpackChunkName: "component---src-pages-sign-up-js" */),
  "component---src-pages-step-1-js": () => import("./../../../src/pages/step1.js" /* webpackChunkName: "component---src-pages-step-1-js" */),
  "component---src-pages-tools-js": () => import("./../../../src/pages/tools.js" /* webpackChunkName: "component---src-pages-tools-js" */),
  "component---src-pages-wind-log-js": () => import("./../../../src/pages/wind_log.js" /* webpackChunkName: "component---src-pages-wind-log-js" */),
  "component---src-pages-windload-js": () => import("./../../../src/pages/windload.js" /* webpackChunkName: "component---src-pages-windload-js" */),
  "component---src-pages-wizard-js": () => import("./../../../src/pages/wizard.js" /* webpackChunkName: "component---src-pages-wizard-js" */),
  "component---src-pages-xlsx-js": () => import("./../../../src/pages/xlsx.js" /* webpackChunkName: "component---src-pages-xlsx-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

