import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios'; // Import Axios
import { navigate } from 'gatsby';
import { logOut } from '../../Redux/Slice1';

const isBrowser = typeof window !== 'undefined';

const initialState = {
  loading: false,
  status: '',
  access: isBrowser && localStorage.getItem('personalAccess') && JSON.parse(localStorage.getItem('personalAccess')) || null,
  refresh: isBrowser && localStorage.getItem('AuthFresh') && JSON.parse(localStorage.getItem('AuthFresh')) || null,
  rejectRefresh: false,
  toggleLog: isBrowser && localStorage.getItem("personalAccess") !== null
};

// login request
export const login = createAsyncThunk('loginSlice/login', async (arg, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const headers = {
      'Content-Type': 'application/json; charset=utf8',
    };
    const body = JSON.stringify(arg);
    const response = await axios.post('https://ccl.apps.domefdh.com/api/login/', body, {
      headers,
    });

    if (response.status !== 200) {
      throw new Error(response.data.detail || 'Refresh failed');
    }
    return response.data;
  } catch (error) {
    throw error;
    return rejectWithValue(error.message);
  }
});

// refresh request
export const getRefresh = createAsyncThunk('loginSlice/getRefresh', async (arg, thunkAPI) => {
  const { rejectWithValue } = thunkAPI;
  try {
    const headers = {
      'Content-Type': 'application/json; charset=utf8',
    };
    const body = JSON.stringify(typeof(arg) == "object" ? arg : {refresh: arg} );
    const response = await axios.post('https://ccl.apps.domefdh.com/api/refreshaccess/', body, {
      headers,
    });

    if (response.status !== 200) {
      throw new Error(response.data.detail || 'Refresh failed');
    }
    return response.data;
  } catch (error) {
    if(error?.response?.status === 401){
      thunkAPI.dispatch(logOut())
    }
    return rejectWithValue(error);
  }
});

const loginSlice = createSlice({
  name: 'loginSlice',
  initialState,
  reducers: {
    logout: (state, action) => {
      return {
        ...state,
        access: null,
        refresh: null,
        toggleLog: false,
      };
    },
  },
  extraReducers: (builder) => {
    // PENDING
    builder
    .addCase(login.pending, (state, action) => {
      return {
        ...state,
        loading: true,
      };
    })
      // SUCCESS
      .addCase(login.fulfilled, (state, action) => {
        if (!action.payload.detail) {
          // access
          isBrowser &&
            localStorage.setItem(
              'personalAccess',
              JSON.stringify({
                access: action.payload.access,
              })
            );
          // refresh
          isBrowser &&
            localStorage.setItem(
              'AuthFresh',
              JSON.stringify({
                refresh: action.payload.refresh,
              })
            );
          navigate('/dashboard');
        }
        return {
          ...state,
          access: action.payload.access,
          refresh: action.payload.refresh,
          loading: false,
          toggleLog: true,
        };
      })
      // REJECTED
      .addCase(login.rejected, (state, action) => {
        console.log('LOGIN REJECT', action.error.message);
        return {
          ...state,
          status: action.error.message,
          loading: false,
        };
      });

      // REFRESH
    builder
    .addCase(getRefresh.pending, (state, action) => {
      return {
        ...state,
      };
    })
      // SUCCESS
    .addCase(getRefresh.fulfilled, (state, action) => {
        // access
        isBrowser &&
          localStorage.setItem(
            'personalAccess',
            JSON.stringify({
              access: action.payload.access,
            })
          );
        return {
            ...state,
            access: action.payload.access,
        }
    })
      // REJECTED
    .addCase(getRefresh.rejected,(state,action)=>{
      if(action.payload?.response?.status === 401){
        localStorage.clear()
        navigate('/login')
        
        return {
          ...state,
          status: '',
          access: null,
          refresh: null,
          toggleLog: false
        }
      }
        return {
            ...state,
        }
    })
  },
});

  export const { logout } = loginSlice.actions;
  export default loginSlice.reducer;
