import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

const isBrowser = typeof window !== "undefined"

const initialState = {
  toggleLog: isBrowser && localStorage.getItem("personalAccess") !== null, // return true or false
}

export const logSlice = createSlice({
  name: "logSlice",
  initialState,
  reducers: {
    loginTrue: (state, action) => {
      return {
        ...state,
        toggleLog: true,
      }
    },
    logOut: (state, action) => {
      return {
        ...state,
        toggleLog: false,
      }
    },
  },
  extraReducers: {},
})

// Action creators are generated for each case reducer function
export const { loginTrue, logOut } = logSlice.actions
export default logSlice.reducer
