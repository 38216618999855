import { createSlice } from "@reduxjs/toolkit"

/*************************************this slice is working for */
const initialState = {
  username: "",
}

const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    getUserName: (state,action)=>{
      return{
        ...state,
        username: action.payload
      }
    },
    removeUser: (state,action)=>{
      return{
        ...state,
        username: null
      }
    }
  },
  extraReducers:{}
})

export default userSlice.reducer
export const { getUserName , removeUser } = userSlice.actions
